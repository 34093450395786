const cleanBaseUrl = () => {
  const protocol = window.location.protocol
  const hostSplit = window.location.host.split('-')

  if (hostSplit[0] === 'localhost:3000') {
    return 'https://pre-mgmt.connectivity.silence.eco'
  } else {
    const urlToBackend = 'mgmt.connectivity.silence.eco'
    if (hostSplit.length === 1) {
      return `${protocol}//${urlToBackend}`
    } else {
      return `${protocol}//${hostSplit[0]}-${urlToBackend}`
    }
  }
}

const cleanBaasBaseUrl = () => {
  const protocol = window.location.protocol
  const hostSplit = window.location.host.split('-')

  if (hostSplit[0] === 'localhost:3000') {
    return 'https://pre-batterystation.connectivity.silence.eco'
  } else {
    const urlToBackend = 'batterystation.connectivity.silence.eco'
    if (hostSplit.length === 1) {
      return `${protocol}//${urlToBackend}`
    } else {
      return `${protocol}//${hostSplit[0]}-${urlToBackend}`
    }
  }
}

const cleanFleetBaseUrl = () => {
  const protocol = window.location.protocol
  const hostSplit = window.location.host.split('-')

  if (hostSplit[0] === 'localhost:3000') {
    return 'https://pre-fleet.connectivity.silence.eco'
  } else {
    const urlToBackend = 'fleet.connectivity.silence.eco'
    if (hostSplit.length === 1) {
      return `${protocol}//${urlToBackend}`
    } else {
      return `${protocol}//${hostSplit[0]}-${urlToBackend}`
    }
  }
}

const baseURL = cleanBaseUrl()
const baasBaseURL = cleanBaasBaseUrl()
const fleetBaseURL = cleanFleetBaseUrl()

const stringConstants = {
  URL_React: window.location + '/home',
  TIME_REFRESH_MAPS: 30000,
  URL_ApiGateway: `${baseURL}/api`,
  URL_Baas: `${baasBaseURL}/api`,
  URL_Fleet: `${fleetBaseURL}/api`,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
}

export default stringConstants

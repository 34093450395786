import { Grid, TextField } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { scooterActions } from '../../../../redux/actions/scooter_actions'
import { formatDateToDisplay } from '../../../shared/formatDate'

const SpecsScooterOnMap = ({ data, getId, dynamic }) => {
  const { t } = useTranslation()

  const {
    activeServiceId,
    bastidorNum,
    bluetoothMAC,
    frameNo,
    hasOwner,
    id,
    imei,
    isHelmetIncluded,
    lastOwnerChange,
    login,
    manufactureDate,
    model,
    name,
    plate,
    revision,
    selectToOperate,
    sharing,
    sharingOperatorId,
    sharingOperatorKey,
    tableData,
    typeDisplay,
    userDisplayData,
    userId,
  } = data || {}

  const {
    analogues,
    devicePower, //{externalInputVoltage: 0, internalBackupBatteryLevel: 10}
    digitals,
    es4,
    gpsData, //{latitude: 41.375488, longitude: 2.085005}
    lastConnectionTimestamp,
    motoState,
    scooterId,
    scutum, //{batterySoc: 9, status: 0}
  } = dynamic || {}

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.name')}
            value={name || 'undefined'}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.model')}
            value={model || 'undefined'}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.lastConnection')}
            value={
              lastConnectionTimestamp
                ? formatDateToDisplay(lastConnectionTimestamp, true)
                : 'undefined'
            }
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.battery')}
            value={(scutum && `${scutum.batterySoc}%`) || 'undefined'}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.id')}
            value={id || 'undefined'}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.hasOwner')}
            value={hasOwner ? 'Si' : 'No'}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.matricula')}
            value={plate || 'undefined'}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.imei')}
            value={imei}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('scooters.details.spec.page.vehicule.codVin')}
            value={frameNo || 'undefined'}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid style={{ marginTop: '2%', cursor: 'pointer' }}>
          <Link color="primary" onClick={() => getId(id)}>
            {t('scooters.details.spec.page.vehicule.dataSheet')}
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

const actionCreators = {
  getId: scooterActions.getId,
}

export default connect(null, actionCreators)(SpecsScooterOnMap)
